export const videoPopup = () => {
    const situationVideo = document.getElementById("has_watched_tutorial")
    const courseVideo = document.getElementById("has_watched")
    const video = situationVideo ? situationVideo : courseVideo
    const undo = document.querySelector(".undo-popup")
    if (video){
        if (video.dataset.play === "false" ) {
            video.click()
        }
        undo.addEventListener("click", () => {
            const name = video.id
            fetch("/update_has_watched", {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: { [name]: true }
                })
            })
            video.click()
        })
    }
}
