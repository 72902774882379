import consumer from "./consumer";

function timeAgo(createdAt) {
  const now = new Date();
  const createdDate = new Date(createdAt);
  const diffInMs = now - createdDate;
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60));

  if (diffInMinutes >= 60) {
    const diffInHours = Math.floor(diffInMinutes / 60);
    return `${diffInHours} ${I18n.t("notifications.hours")}`;
  } else {
    return `${diffInMinutes} ${I18n.t("notifications.minutes")}`;
  }
}

consumer.subscriptions.create("ApplicationCable::NotificationChannel", {
  connected() {
    console.log("Conectado al NotificationChannel");
  },

  disconnected() {
    console.log("Desconectado del NotificationChannel");
  },

  received(data) {
    console.log("Received data", data);

    if (data?.notifications) {
      const notificationsList = document.querySelectorAll(
        ".notifications-list"
      );

      if (notificationsList) {
        const title4 = document.createElement("h4");
        title4.textContent = I18n.t("notifications.title");

        notificationsList.forEach((notif) => {
          const title = notif.querySelector("h4");
          const paragraph = notif.querySelector("p.text-muted");

          if (title) title.remove();
          if (paragraph) paragraph.remove();

          const notification = data.notifications;
          const notificationCard = document.createElement("div");
          notificationCard.classList.add("notification-card-bar");
          notificationCard.innerHTML = `
        <p class="notification-text-bar">
          <strong>${notification.message}</strong> ${I18n.t(
            "notifications.situation"
          )}
          <span class="notification-highlight">${
            notification.situation_name
          }</span>
        </p>
        <p class="notification-info">
          Hace ${timeAgo(notification.created_at)} · ${notification.skill_name}
        </p>
        <form action="/notifications/clear_and_redirect" method="post" style="display:inline;">
          <input type="hidden" name="_method" value="delete">
          <input type="hidden" name="authenticity_token" value="${document
            .querySelector('meta[name="csrf-token"]')
            .getAttribute("content")}">
          <button type="submit" class="btn-feedback">${I18n.t(
            "notifications.feedback_button"
          )}</button>
        </form>
        <hr class="divider">
      `;

          notif.insertBefore(notificationCard, notif.firstChild);
          notif.insertBefore(title4, notif.firstChild);
        });

        const notifBells = document.querySelectorAll(".notif-bell");
        notifBells.forEach((notifBell) => {
          notifBell.classList.add("has-notifications");
        });
      }
    }
  },
});
