export const situationDisplay = () => {
  const situationBtn = document.querySelectorAll("#situation-display");
  const situationVideo = document.querySelectorAll(".situation-video");
  const overlay = document.querySelector(".overlay");
  if (situationBtn) {
    situationBtn.forEach((situation, i) => {
      situation.addEventListener("click", (e) => {
        e.preventDefault();
        const distinctId = situation.dataset["distinctId"];
        mixpanelSituationsClickEvent(distinctId, "See Situation Video Clicked");
      });
      if (situation.dataset["display"] === "true") {
        situation.addEventListener("click", () => {
          situationVideo[i].classList.add("active");
          overlay.classList.add("active");
        });
      }
    });
  }
  closeSituationRecording();
};

export const situationDisplayAll = () => {
  const situationBtns = document.querySelectorAll("#situation-display");
  const situationVideo = document.querySelectorAll(".situation-video");
  const overlay = document.querySelector(".overlay");
  situationBtns.forEach((situation, i) => {
    situationVideo[i].classList.add("active");
    overlay.classList.add("active");
  });
};

export const videoaskDisplay = () => {
  const btnAttempt = document.querySelectorAll(".btn-attempt");
  const situationAttempts = document.querySelectorAll(".situation-attempts");
  if (btnAttempt) {
    btnAttempt.forEach((btn, i) => {
      btn.addEventListener("click", (e) => {
        e.preventDefault();
        situationAttempts[i].classList.add("active");
      });
    });
  }
};

const closeSituationRecording = () => {
  const clostBtns = document.querySelectorAll(".situation-close");
  const situationVideo = document.querySelectorAll(".situation-video");
  const overlay = document.querySelector(".overlay");
  clostBtns.forEach((button, index) => {
    button.addEventListener("click", () => {
      situationVideo[index].classList.remove("active");
      overlay.classList.remove("active");
    });
  });
};

const mixpanelSituationsClickEvent = (userId, eventName) => {
  fetch("/mixpanel_track_click_event", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId: userId,
      eventName: eventName,
    }),
  });
};
