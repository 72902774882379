import $ from 'jquery';
import {mixpanelCreateEvent} from './mixpanelCreateEvent';
import {processDate} from './date';

const displayModalOverlay = () => {
  let event;
  const undoPopup = document.getElementById("no-mostrar-btn");
  $(".overlay-src").click(function() {
    $("#overlay-modal").modal('show');
    // Gets the video src from the src of the iframe
    const overlayVideo = document.getElementById("overlay-video");
    var $videoSrc;
    $videoSrc = this.dataset.url;
    event = this.dataset.event;
    overlayVideo.src = $videoSrc;
    const autoplay = this.dataset.play;
    const player = new Vimeo.Player(overlayVideo);
    if (undoPopup !== null) {
      if (event === "motivationVideo"){
        undoPopup.classList.add("invisible")
      } else {
        undoPopup.classList.remove("invisible")
      }
    }
    // when the modal is opened autoplay it
    $('#overlay-modal').on('shown.bs.modal', function (e) {
      // set the video src to autoplay and not to show related video.
      // autoplay
      if (autoplay == 'false'){
        console.log("playing")
        try{
          player.play()
        } catch(err){
          console.log(err)
        }
      }
    })
  })
  // stop playing the youtube video when I close the modal
  $('#overlay-modal').on('hide.bs.modal', function () {
    console.log("closing")
    let player = new Vimeo.Player($("#overlay-video")[0]);
    let videoDuration;
    try{
      player.pause();
      player.getDuration().then(function(duration) {
        videoDuration = duration;
      });
      player.getCurrentTime().then(function(time) {
        let percentWatched = (time * 100)/videoDuration;
        switch (event){
          case 'tutorialVideo':
            updateVideoPercentage(event, percentWatched)
            mixpanelCreateEvent("Has Seen Como Grabarse Video", {"percentage_watched": Math.round(percentWatched)}, {})
            break;
          case 'introVideo':
            updateVideoPercentage(event, percentWatched)
            mixpanelCreateEvent("Has Seen Como Funciona Video", {"percentage_watched": Math.round(percentWatched)}, {});
            break;
          case 'motivationVideo':
            updateVideoPercentage(event, percentWatched)
            break;
        }
      })
      // overlayVideo.src = $videoSrc;
    }catch(e) {
      console.log(e)
    }
  })
}

const updateVideoPercentage = (event, percentage) => {
  fetch("/update_video_percentage", {
    method: "PATCH",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    body: JSON.stringify({ 
      percent_watched: percentage,
      event: event
    })
})
}

const resourceModal = () => {
  const resourceCards = document.querySelectorAll('.modal-alink');
  const resourcesVideo = document.querySelector('.resources-modal');
  const videoModal = document.querySelector('.video-modal');
  // const exitBtn = document.querySelector('.fa-times');
  let src;

  if (videoModal) {
    resourceCards.forEach(resourceCard => {
      resourceCard.addEventListener('click', e => {
        src = e.currentTarget.dataset.src
            resourcesVideo.src = `${src}`
            videoModal.classList.add('active')
      })
    })
    videoModal.addEventListener('click', e => {
      if (e.target.classList.contains('active' ) || e.target.classList.contains('fa-times')){
        videoModal.classList.remove('active')
        resourcesVideo.src = ``
      } 
    })
  }

}

export const lernmiVideoDisplay = () => {
  const lernmiVideo = document.getElementById('lernmi-video')
  if (lernmiVideo){
      if (window.location.pathname === "/") {
          lernmiVideo.click()
      }
  }
}

const diasDeLaSemana = new Map([
  ['Monday', 'Lunes'],
  ['Tuesday', 'Martes'],
  ['Wednesday', 'Miércoles'],
  ['Thursday', 'Jueves'],
  ['Friday', 'Viernes'],
  ['Saturday', 'Sábado'],
  ['Sunday', 'Domingo']
]);

export const userReminderModal = () => {
  const userReminderPopUp = document.querySelector(".user-reminder-popup");
  if (userReminderPopUp){
    disableSetReminder()
    const registerDateBtn = document.getElementById('set-reminder-btn');
    registerDateBtn.addEventListener('click', () => {
      I18n.locale = document.querySelector('meta[name="user-locale"]').content
      var lang = document.querySelector('meta[name="user-locale"]').content
      const cardText = document.querySelector('.card-text');
      const selectedDaysOfTheWeek = document.querySelectorAll("#reminder-datetime:checked");
      const selectedFrequency = "weekly";
      const time = document.getElementById("time").value;
      var frequencyText = " "+ I18n.t('user_reminder.with_frequency_html');

      const daysSelected = Array.from(selectedDaysOfTheWeek).map((current_value, index, array) => lang === "es" ? diasDeLaSemana.get(current_value.value) : current_value.value)

      let date_value = daysSelected.join(', ');
      date_value = date_value.replace(/,([^,]*)$/, ' y$1');

      userReminderPopUp.classList.add("active");
      frequencyText = selectedFrequency.value == "Frequency" ?  "" : frequencyText;
      cardText.innerHTML = I18n.t('user_reminder.popup_description', {date: date_value, time: time, frequencyText: frequencyText});
      mixpanelCreateEvent("Set User Reminder", {"Reminder day": `${date_value}`, "Reminder hour": `${time}`}, {})
    })
  }
}

const disableSetReminder = () => {
  const daysOfTheWeek = document.querySelectorAll("#reminder-datetime");
  const setReminder = document.getElementById("set-reminder-btn");
  daysOfTheWeek.forEach(el => {
    el.addEventListener("change", () => {
      let selectedDays = document.querySelectorAll("#reminder-datetime:checked");
      if (selectedDays.length > 0) {
        setReminder.classList.remove("disabled")
        setReminder.disabled = false
      } else {
        setReminder.classList.add("disabled")
        setReminder.disabled = true
      }
    })
  })
}

export const displayNotice = () => {
  const situationModal = document.getElementById("situations-modal-card")
  const modalCard = document.querySelector(".modal-card")
  if (situationModal) {
    const dontDisplay = document.getElementById("update-no-display")
    const closeModal = document.querySelector(".close-modal")
    const viewSituationBtns = document.querySelectorAll("#situation-display")
    const userSkillId = dontDisplay.dataset["userSkillId"]
    let notificationStatus = viewSituationBtns[0].dataset["notificationDisplay"]
    
    viewSituationBtns.forEach(btn => {
      btn.addEventListener('click', () => {
        console.log("Notification Status", notificationStatus)
        if (notificationStatus === 'true') {
          modalCard.classList.add("active");
        }
      })
    })
  
    dontDisplay.addEventListener("click", () => {
      modalCard.classList.remove("active");
      notificationStatus = false
      fetch(`/user_skills/${userSkillId}`, {
        method: "PATCH",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
      })
    })
  
    closeModal.addEventListener("click", () => {
        modalCard.classList.remove("active")
    })
  }
}

export const displayMailMasterNotice = () => {
  const mailMasterModal = document.getElementById("mail-master-modal-card")
  const modalCard = document.querySelector(".modal-card")
  if (mailMasterModal) {
    const dontDisplay = document.getElementById("update-no-display")
    const closeModal = document.querySelector(".close-modal")
    let notificationStatus = mailMasterModal.dataset["display"]

    document.addEventListener('DOMContentLoaded', () => {
      console.log("Notification Status", notificationStatus)
      if (notificationStatus === 'true') {
        modalCard.classList.add("active");
      }
    })

    dontDisplay.addEventListener("click", () => {
      modalCard.classList.remove("active");
      fetch('/mail_master/update_mail_master_notice', {
        method: "PATCH",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      })
    })
  
    closeModal.addEventListener("click", () => {
      modalCard.classList.remove("active")
    })
  }
}

export const displayMailTrainingRoomNotice = () => {
  const trainingRoomModal = document.getElementById("training-room-modal-card")
  if (trainingRoomModal) {
    const dontDisplay = document.getElementById("update-no-display")
    const closeModal = trainingRoomModal.querySelector(".close-modal")
    let notificationStatus = trainingRoomModal.dataset["display"]

    document.addEventListener('DOMContentLoaded', () => {
      console.log("Notification Status", notificationStatus)
      if (notificationStatus === 'true') {
        trainingRoomModal.classList.add("active");
      }
    })

    dontDisplay.addEventListener("click", () => {
      trainingRoomModal.classList.remove("active");
      fetch('/lernmi_video_vault/update_training_room_notice', {
        method: "PATCH",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        }
      })
    })
  
    closeModal.addEventListener("click", () => {
      trainingRoomModal.classList.remove("active")
    })
  }
}


export const displayMiniGameNotice = () => {
  const modalCard = document.querySelector(".modal-card")
  if (modalCard) {
    const closeModal = document.querySelectorAll(".close-modal")
    let notificationStatus = modalCard.dataset["display"]

    document.addEventListener('DOMContentLoaded', () => {
      console.log("Notification Status", notificationStatus)
      if (notificationStatus === 'true') {
        modalCard.classList.add("active");
      }
    })
  
    closeModal.forEach(closeModalButton => {
      closeModalButton.addEventListener("click", () => {
        modalCard.classList.remove("active")
      })
    })
  }
}


export const displaySituationSuggestionNotice = () => {
  const modalCard = document.querySelector(".modal-card")
  if (modalCard) {
    const closeModal = document.querySelector(".close-modal")
    let notificationStatus = modalCard.dataset["display"]
    const sendBtn = document.getElementById("send-situation-suggestions-btn");

    document.addEventListener('DOMContentLoaded', () => {
      console.log("Notification Status", notificationStatus)
      if (notificationStatus === 'true') {
        modalCard.classList.add("active");
      }
    })
  

    closeModal.addEventListener("click", () => {
      updateUserSituationSuggestion();
      modalCard.classList.remove("active");
    })

    sendBtn.addEventListener("click", () => {
      updateUserSituationSuggestion();
      modalCard.classList.remove("active");

    })

  }
}

const updateUserSituationSuggestion = () => {
  const inputContent = document.querySelector(".situation-suggestion-input").value;
  fetch("/update_situation_suggestion_notice", {
    method: "PATCH",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      content: inputContent
    })
  });
}


export const challengeCreateNotice = () => {
  const challengeModal = document.getElementById("create-challenge-modal-card");
  const challengeCreateBtn = document.getElementById("create-challenge-btn");

  
  if (challengeModal) {
    const closeModal = challengeModal.querySelector(".close-modal")

    challengeCreateBtn.addEventListener("click", e => {
      e.preventDefault();
      const theme = document.getElementById("challenge_theme");
      const context = document.getElementById("challenge_context");
      const message = document.getElementById("challenge_message");
      
      fetch('/client/create', {
        method: "POST",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          challenge: {
            theme: theme.value,
            message: message.value,
            context: context.value
          }
        }),
      })
      challengeModal.classList.add("active");
    })
  
    closeModal.addEventListener("click", () => {
      challengeModal.classList.remove("active")
    })
  }
}

export { displayModalOverlay, resourceModal};
