export const updateNotification = () => {
    const notificationBtns = document.querySelectorAll(".notification-bell");
    if (notificationBtns) {
        const updateNotification = (user, value) => {
            fetch("/notification_update", {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user: user,
                    value: value
                })
            })
        }
        notificationBtns.forEach(btn => {
            const user = parseInt(btn.dataset.userId)
            btn.addEventListener("click", event => {
                console.log("clicked")
                event.preventDefault();
                if (btn.firstElementChild.classList.contains("fa-bell")) {
                    btn.firstElementChild.classList.add("fa-bell-slash")
                    btn.firstElementChild.classList.remove("fa-bell")
                    updateNotification(user, false)
                } else {
                    btn.firstElementChild.classList.add("fa-bell")
                    btn.firstElementChild.classList.remove("fa-bell-slash")
                    updateNotification(user, true)
                }
            })
        })
    }
}

export const updateUserActiveStatus = () => {
    const userActiveStatusBtn = document.querySelectorAll("#user-active-status")
    const notificationBtns = document.querySelectorAll(".notification-bell");

    if (userActiveStatusBtn) {
        const updateStatus = (userId, boolean) => {
            fetch(`/update_active_status/${userId}`, {
                method: "PATCH",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    boolean: boolean,
                })
            })
        }
        
        userActiveStatusBtn.forEach((btn, i) => {
            btn.addEventListener("click", event => {
                const userId = btn.dataset["userId"]
                const notificationBtn = notificationBtns[i]

                if (btn.firstElementChild.classList.contains("fa-user")) {
                    btn.firstElementChild.classList.add("fa-user-slash")
                    btn.firstElementChild.classList.remove("fa-user")

                    if (notificationBtn.firstElementChild.classList.contains("fa-bell")) {
                        notificationBtn.firstElementChild.classList.add("fa-bell-slash")
                        notificationBtn.firstElementChild.classList.remove("fa-bell")
                    }

                    updateStatus(userId, false)
                } else {
                    btn.firstElementChild.classList.add("fa-user")
                    btn.firstElementChild.classList.remove("fa-user-slash")
                    
                    if (notificationBtn.firstElementChild.classList.contains("fa-bell-slash")) {
                        notificationBtn.firstElementChild.classList.add("fa-bell")
                        notificationBtn.firstElementChild.classList.remove("fa-bell-slash")
                    }

                    updateStatus(userId, true)
                }
            })
        })
    }
}