import { updateNotification } from './updateUser';
import { commentsDropdown } from './dropDown';

const clientPrograms = document.getElementById('client-programs');
const programCourses = document.getElementById('program-courses');
const programUsers = document.getElementById('program-users');
const clientProgramsDashboard = document.getElementById('client-programs-client-dashboard');


const refreshSkills = () => {
  const clientInput = document.getElementById('user_client_id');
  if (clientInput){
  
    if (clientPrograms) {
      if (clientInput.value !== "") {
        const selectedClient = clientInput.options[clientInput.options.selectedIndex].value
        clientPrograms.innerHTML = '';
        retrievePrograms(parseInt(selectedClient));
        onChangeInput(clientInput);
      } else {
        onChangeInput(clientInput);
      }
      onChangeProgram(clientPrograms);
    }
  }  
}

export const programCheckbox = () => {
  const clientInput = document.getElementById('user_client_id');
  if (clientProgramsDashboard) {
    if (clientInput.value !== "") {
      const selectedClient = clientInput.options[clientInput.options.selectedIndex].value
      clientProgramsDashboard.innerHTML = '';
      retrieveProgramsCheckbox(parseInt(selectedClient));
    } else {
      onChangeClientInput(clientInput);
    }
  }
}

const onChangeClientInput = (clientInput) => {
  clientInput.addEventListener('change', (event) => {
    const selectedClient = event.currentTarget.options[clientInput.options.selectedIndex].value
    clientProgramsDashboard.innerHTML = '';
    if (programCourses) {
      programCourses.innerHTML = '';
    } else if (programUsers) {
      programUsers.innerHTML = '';
    }
    retrieveProgramsCheckbox(parseInt(selectedClient));
  });
}

const onChangeInput = (clientInput) => {
  clientInput.addEventListener('change', (event) => {
    const selectedClient = event.currentTarget.options[clientInput.options.selectedIndex].value
    clientPrograms.innerHTML = '';
    if (programCourses) {
      programCourses.innerHTML = '';
    } else if (programUsers) {
      programUsers.innerHTML = '';
    }
    retrievePrograms(parseInt(selectedClient));
  });
}

const onChangeProgram = (clientPrograms) => {
  clientPrograms.addEventListener('change', (event) => {
    const selectedProgram = clientPrograms.options[clientPrograms.options.selectedIndex].value
    if (programCourses) {
      programCourses.innerHTML = '';
      retrieveCourses(parseInt(selectedProgram));
    } else if (programUsers){
      programUsers.innerHTML = '';
      retrieveUsers(parseInt(selectedProgram));
    }
  })
}

const retrievePrograms = (client_id) => {
  fetch(`/client_programs/${client_id}`)
    .then(response => response.json())
    .then((data) => {
      console.log("Retrieve Courses");
      clientPrograms.innerHTML = "";
      clientPrograms.insertAdjacentHTML("beforeend", '  <option disabled selected value> -- select an option -- </option> ');
      data.forEach((program) => {
          const programItem = `<option value=${program.id}>${program.name}</option>`;
          clientPrograms.insertAdjacentHTML("beforeend", programItem);
      });
    });
};

const retrieveProgramsCheckbox = (client_id) => {
  fetch(`/client_programs/${client_id}`)
    .then(response => response.json())
    .then((data) => {
      console.log("Retrieve Courses");
      clientProgramsDashboard.innerHTML = "";
      data.forEach((program) => {
        const programItem = `<div class="d-flex justify-content-between align-items-center">
          <div>
            <input type="checkbox" name="programs[${program.id}]">
            <label for="${program.name}">${program.name}</label>
          </div>
        </div>`
        clientProgramsDashboard.insertAdjacentHTML("beforeend", programItem);
      });
    });
};

const retrieveCourses = (program_id) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const userId = url === "http://localhost:3000/users/sign_up" ? null : url.searchParams.get("user_id")
  fetch(`/program_courses/${program_id}/${userId}`, { headers: { accept: "application/json" } })
  .then(response => response.json())
  .then((data) => {
    console.log("Retrieve Courses");
    console.log(data);
    const userSkills = data[1];
    const ids = userSkills.map((arr) => arr[0]);
    data[0].forEach((course) => {
      if (course.skills.length > 0) {
        const courseItem = `<h5>${course.name}</h5>`;
        programCourses.insertAdjacentHTML("beforeend", courseItem);
      }
      course.skills.forEach((skill) => {
          const skillItem = `<div class="d-flex justify-content-between align-items-center">
                <div>
                  <input type="checkbox" name="skills[${skill.id}]" ${ids.includes(skill.id) ? "checked" : ""}>
                  <label for="${skill.name}">${skill.alias}</label>
                </div>
                <div>
                  <input type="checkbox" name="skills[${skill.id}]" value="disable" ${ids.indexOf(skill.id) >= 0 ? (userSkills[ids.indexOf(skill.id)][1] === "active" ? "" : "checked") : ""} id="skill_disable">
                  <label for="skill_disable">disable</label>
                </div>
              </div>`
          programCourses.insertAdjacentHTML("beforeend", skillItem);
        });

      });

    });
};

const retrieveUsers = (user_id) => {
  fetch(`/program_users/${user_id}`, { headers: { accept: "application/json" } })
    .then(response => response.json())
    .then((data) => {
      console.log("Retrieve Courses");
      console.log(data);
      data.forEach((user) => {
          const userItem = 
            `<div class="d-flex justify-content-between align-items-center">
                <div>
                  <input type="checkbox" name="users[${user.id}]" >
                  <label for="${user.first_name}">${user.first_name} ${user.last_name}</label>
                </div>
              </div>`
          programUsers.insertAdjacentHTML("beforeend", userItem);
      });
    });
};


export const userSupervision = () => {
  const programSelected = document.getElementById("program-supervision");
  const tableBody = document.getElementById("supervised-table-body");
  const downloadBtn = document.getElementById("send-program-report");
  if (programSelected){
    if (programSelected.value === "") {
      programSelected.addEventListener("change", event => {
        event.preventDefault
        tableBody.innerHTML = '';
        downloadBtn.classList.remove("d-none");
        userSupervisionOnChange(programSelected)
      })
    } else {
      userSupervisionOnChange(programSelected)
      programSelected.addEventListener("change", event => {
        event.preventDefault
        tableBody.innerHTML = '';
        userSupervisionOnChange(programSelected)
      })
    }
  }
}

const userSupervisionOnChange = (programSelected) => {
  getSupervisedUsers(programSelected.value)
}


const applyJSToSupervision = (programId) => {
    updateNotification()
    commentsDropdown()
    userProgramComment()
}

const getSupervisedUsers = (programId) => {
  const tableBody = document.getElementById("supervised-table-body");
  fetch(`/program_users_supervision/${programId}`, { headers: { accept: "application/json" } })
    .then(response => response.json())
    .then((data) => {
      data[0].forEach((student) => {
        const userItem = 
            `
            <tr class="table-row">
                <td>${student[0].first_name} ${student[0].last_name}</td> 
                <td>${(student[0].phone_number == null) ? "" : student[0].phone_number}</td>
                <td>${(student[0].email == null) ? "" : student[0].email}</td>
                <td>${student[0].coach.first_name + " " + student[0].coach.last_name }</td>
                <td>${(student[1] === null) ? "N/A" : student[1] }</td>
                <td>${student[2]}</td>
                <td>${student[5]}</td>
                <td>${student[3]}</td>
                <td>${student[4]}</td>
                <td>
                  <div class="feedback-comment ${student[5].length > 0 ? 'active' : '' }">
                    <i class="fas fa-plus"></i>
                    <textarea name="user_program_comment" id="user-program-comment" data-user="${student[0].id}" data-program-id="${programId}"">${student[5]}</textarea>
                  </div>
                </td>
                <td>
                    <button class="btn btn-action my-auto mx-auto notification-bell" data-user-id="${student[0].id}">
                        <i class="${(student[0].notification) ? "far fa-bell" : "far fa-bell-slash"}"></i>
                    </button>
                </td>
                <td><a class="btn btn-action" href="/student/${student[0].id}?program=${programId}" name="user-${student[0].id}">Stat</a></td>
            </tr>`
        tableBody.insertAdjacentHTML("beforeend", userItem);
      });
      applyJSToSupervision(programId)
    });
}

export const userProgramComment = () => {
  const userComments = document.querySelectorAll("#user-program-comment");
  if ( userComments) {
    userComments.forEach(comment => {
      comment.addEventListener("input", event => {
        const content = {"comment": event.currentTarget.value}
        const userId = event.currentTarget.dataset["user"]
        const programId = event.currentTarget.dataset["programId"]
        updateUserProgram(userId, programId, content)
      })
    })
  }
}

export const updateUserProgram = (user_id, program_id, content) => {
  fetch(`/update_users_program/${program_id}/${user_id}`, {
    method: "PATCH",
    headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
    },
    body: JSON.stringify({
      content: content
    })
  })
}

export { refreshSkills };
