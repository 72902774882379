import { mixpanelCreateEvent } from './mixpanelCreateEvent';
import { updateUserProgram } from './refreshClientSkills';

let selectedScore;

export const npsPopup = () => {
    const npsContainer = document.querySelector(".nps-container");
    const page = window.location.href;
    if (npsContainer) {
        npsContainer.classList.add("active")
        const programId = npsContainer.dataset["programId"]
        const userId = npsContainer.dataset["userId"]
        const isHomePage = page.includes("user_home")

        exitNps(npsContainer, userId, programId, isHomePage)
        scoredNps(userId, programId, isHomePage)
        npsCommentSubmit(npsContainer)
    }
}

const exitNps = (npsContainer, userId, programId, isHomePage) => {
    const exitBtn = document.querySelector(".fa-times");
    const currentDate = new Date().toISOString();

    exitBtn.addEventListener("click", () => {
        npsContainer.classList.remove("active")
        mixpanelCreateEvent("NPS Exit", {"nps_exited": true}, {})
        if (isHomePage){
            updateUserProgram(userId, programId, {"nps_second_reminder": true})
        } else {
            updateUserProgram(userId, programId, {"nps": true, "nps_presented_date": currentDate})
        }
    })
}

const scoredNps = (userId, programId, isHomePage) => {
    const npsValues = document.querySelectorAll(".nps-btn");
    const npsRatingContent = document.getElementById("nps-rating-content");
    const npsCommentBox = document.querySelector(".nps-comment");
    
    const currentDate = new Date().toISOString();

    npsValues.forEach(btn => {
        btn.addEventListener("click", event => {
            const selectedScore = event.currentTarget.value;
            
            npsRatingContent.style.display = "none";
            npsCommentBox.classList.add("active");

            mixpanelCreateEvent("NPS Scored", {"nps_score": selectedScore}, {})

            if (isHomePage){
                updateUserProgram(userId, programId, {"nps_second_reminder": true, "nps_date": currentDate})
            } else {
                updateUserProgram(userId, programId, {"nps": true, "nps_date": currentDate, "nps_presented_date": currentDate})
            }
        })
    })
}

const npsCommentSubmit = (npsContainer) => {
    const npsSubmit = document.getElementById("nps-submit");
    const npsSkip = document.getElementById("nps-skip");
   
    npsSubmit.addEventListener("click", event => {
        const npsCommentBox =  document.getElementById("nps-textbox").value;
        npsContainer.classList.remove("active")
        mixpanelCreateEvent("NPS Comment", {"nps_comment": npsCommentBox}, {})
    })
    npsSkip.addEventListener("click", event => {npsContainer.classList.remove("active")})
}

const npsDesign = () => {
    var clicked = false;

    var enterHoverFunction = function(){
        var $this = $(this);
        var $prevAll = $(this).prevAll();
        var className = $this.attr("class") + "-hover";
        
        if (clicked === false) {
            $this.addClass(className);
            $prevAll.addClass(className);
        }
    }
    
    var exitHoverFunction = function() {
        var $this = $(this);
        var $prevAll = $(this).prevAll();
            
        if (clicked === false) {
            $this.removeClass("detractor-hover passive-hover promoter-hover");
            $prevAll.removeClass("detractor-hover passive-hover promoter-hover");
        }
     }

    var clickFunction = function(){
        var $this = $(this);
        var $prevAll = $(this).prevAll();
        var $nextAll = $(this).nextAll();
        var value = parseInt($this[0].value)

        $this.removeClass("detractor-hover passive-hover promoter-hover");
        $prevAll.removeClass("detractor-hover passive-hover promoter-hover");
        $nextAll.removeClass("detractor-hover passive-hover promoter-hover");
        
        var className = $this.attr("class") + "-hover";
        clicked = true;
        selectedScore = value;

        $this.addClass(className);
        $prevAll.addClass(className);
    }

    $('.nps-btn').hover(enterHoverFunction, exitHoverFunction).click(clickFunction)
}